<template>
  <div style="height: 100%;">
    <main-navigation nav-drawar-color="light-theme" light>
      <template v-slot:nav-items>
        <!-- <v-list-item
          :to="{ name: 'ProductionDashboard' }"
          exact
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          v-if="activeScope('ecomm_orders')"
          :to="{ name: 'EcommOrderList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>



        <v-list-item
          v-if="activeScope('ecomm_settings')"
          :to="{name: 'EcommTagList'}"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tags</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </main-navigation>

    <div class="app-layout pr-4">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainNavigation from '@/components/core/MainNavigation.vue'

export default {
  name: 'EcommLayout',
  components: {
    MainNavigation
  },
  data: () => ({
    drawer: true
  }),
  apollo: {

  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    userScopes() {
      return this.$store.getters.userScopes
    }
  },
  created() {
    // this.$vuetify.theme.themes.light.primary = '#3F51B5'
    // this.$vuetify.theme.themes.light.secondary = '#53d3d1'
    // this.$vuetify.theme.themes.light.accent = '#fbeceb'
    // this.$vuetify.theme.themes.light.error = '#FF5252'
    // this.$vuetify.theme.themes.light.info = '#2196F3'
    // this.$vuetify.theme.themes.light.success = '#4CAF50'
    // this.$vuetify.theme.themes.light.warning = '#FFC107'

    this.$vuetify.theme.themes.light.primary = '#002447'
    this.$vuetify.theme.themes.light.secondary = '#53d3d1'
    this.$vuetify.theme.themes.light.accent = '#fbeceb'
    this.$vuetify.theme.themes.light.error = '#FF5252'
    this.$vuetify.theme.themes.light.info = '#2196F3'
    this.$vuetify.theme.themes.light.success = '#4CAF50'
    this.$vuetify.theme.themes.light.warning = '#FFC107'
  },
  methods: {
    activeScope(scope) {
      var idx = this._.findIndex(this.userScopes, function(item) {
        return item.name === scope && item.accessLevel > 0
      })
      if (idx > -1) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.page-wrap {
  --page-header-height: 64px;
  /* --page-wrap-offset: -20px; */
}
</style>
